<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              {{ displayText("data_chart.title", "報表中心") }}
            </b-breadcrumb-item>

            <b-breadcrumb-item active>{{
              displayText("data_chart.partner_worker_chart", "夥伴員工統計")
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div
        class="
          d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
          justify-content-between
          mb-4 mb-xl-2
        "
      >
        <h4
          class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold"
        >
          {{ displayText("data_chart.partner_worker_chart", "夥伴員工統計") }}
        </h4>
        <div class="row-searchbar d-flex mb-2 mb-md-0 mr-md-2">
            <h6 class="mr-2 my-auto">
                {{ displayText("data_chart.partner_worker_chart_search", "Consent Date") }}
            </h6>
            <DateSearchPicker
                class="mr-md-2 mr-0"
                v-model="query"
                :disabledEndAtDates.sync="disabledEndAtDates"
                :startedPlaceholder="displayText('data_chart.partner_worker_chart_search_start_date', 'Start Date')"
                :endedPlaceholder="displayText('data_chart.partner_worker_chart_search_end_date', 'End Date')"
            />

            <b-button @click="handleSearch"><i class="fa fa-search"></i></b-button>
            <b-button class="ml-2" variant="primary" @click="handleExport"
            >{{ displayText('data_chart.partner_worker_chart_export', '匯出') }}</b-button
            >
        </div>
    </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="partnerWorkers"
                :fields="fields"
                show-empty
              >
                <template #empty>
                    <div class="text-center text-muted">
                    <p>查無資料</p>
                    </div>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="container" style="margin-top: 80px">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-auto mb-3">
              <span>目前第 {{ currentPage }} 頁 / 總共 {{ Math.ceil(totalRows / query.per_page) }} 頁</span>
            </div>

            <div class="col-12 col-md-auto mb-3">
              <b-pagination
                class="my-auto"
                v-if="totalRows > 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="query.per_page"
              ></b-pagination>
            </div>

            <div class="col-12 col-md-auto mb-3">
              <span>每頁顯示數量：</span>
              <b-form-select
                v-model="query.per_page"
                :options="perPageOption"
                class="d-inline-block"
                style="width: auto;"
                @change="getPartnerWorkers"
              ></b-form-select>
              <span> / 總數量: {{ totalRows }}</span>
            </div>
          </div>
        </div>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapState, mapGetters } from "vuex";
import dataChartApi from "@/apis/data-chart";
import { displayText } from "@/utils/dashboardUtils";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker";

export default {
  components: {
    DateSearchPicker,
  },
  data() {
    return {
      perPageOption: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      currentPage: 1,
      totalRows: 0,
      showLoading: true,
      partnerWorkers: [],
      query: {
        start_at: "",
        end_at: "",
        per_page: 10,
        page: 1,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      dashboardModule: (state) => state.dashboardModule,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    disabledEndAtDates() {
      return { to: new Date(this.query.start_at) };
    },
    fields() {
      let fields = [
        {
          key: "staff_dept_name",
          label: displayText("staff.staff_dept_name", "Division"),
        },
        {
          key: "staff_branch_name",
          label: displayText("staff.staff_branch_name", "Area"),
        },
        {
          key: "staff_name",
          label: displayText("staff.staff_name", "MR"),
        },
        {
          key: "employee_code",
          label: displayText("partner.worker_code", "HCP Unique ID"),
        },
        {
          key: "name",
          label: displayText("partner.worker_name", "HCP Name"),
        },
        {
          key: "mobile",
          label: displayText("partner.mobile", "HCP Mobile"),
        },
        {
          key: "partner_name",
          label: displayText("partner.title", "HCO Name"),
        },
        {
          key: "job_specialty",
          label: displayText("partner.worker_job_specialty", "Specialty"),
        },
        {
          key: "enabled_at",
          label: displayText("partner.worker_enabled_at", "Consent Date"),
          formatter: (value) => {
            if (value) {
              return format(new Date(value), "yyyy-MM-dd HH:mm")
            }
            return ""
          },
        },
        {
          key: "line_bind_at",
          label: displayText("partner.worker_line_join_at", "Join LINE time"),
          formatter: (value) => {
            if (value) {
              return format(new Date(value), "yyyy-MM-dd HH:mm")
            }
            return ""
          },
        },
      ];
      return fields;
    },
  },
  watch: {
    currentPage: function (newPage, oldPage) {
      if (newPage === oldPage) return;
      this.query.page = newPage;
      this.getPartnerWorkers();
    },
  },
  async created() {
  },
  async mounted() {
    await this.getPartnerWorkers();
  },
  methods: {
    format,
    displayText,
    handleSearch() {
      this.currentPage = 1;
      this.getPartnerWorkers();
    },
    async handleExport() {
      let query = this.query;
      dataChartApi.exportPartnerWorkerChart(query).then(() => {
        this.$swal({
          title: '成功',
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: 'success',
          confirmButtonText: '前往查看',
        }).then((result)=>{
            if (result.value) {
              this.$router.push({
                name: "MyConsoleDownloadFileList",
                params: { org_code: this.$route.params.org_code },
              });
            }
          });
      })
    },
    async getPartnerWorkers() {
      this.showLoading = true;
      try {
        const { data } = await dataChartApi.getPartnerWorkerChart(
          this.query
        );
        console.log(this.query)
        this.partnerWorkers = data.data
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: displayText("partner.api_failed", "讀取資料錯誤"),
          });
        }
      }
      this.showLoading = false;
    },
  },
};
</script>
<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
.row-searchbar {
  width: 45%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
